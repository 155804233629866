export const siteData = {
  "name": "MOTURA",
  "address": "99 T RTE NATIONALE LA GAVOTTE,",
  "postCode": "13170",
  "city": "les",
  "phone": "0577931330",
  "url": "https://motura.store",
  "urlSub": "motura.store",
  "price": "price_1Q550jFqI0O2gn3joqPLw3Cm",
  "pk": "pk_live_51Q54tRFqI0O2gn3jjuj5wachzFa4tnXfNayryOrUgVOmrDIgEmbD317agBpRAiK4Pq2cSOekG0X0w90gEDNlfO3n00wVBtem57",
  "sk": "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"
};